<template>
  <div class="yt-main">
    <div class="yt-breadcrumb-wrapper">
      <el-breadcrumb class="yt-breadcrumb">
        <el-breadcrumb-item>测评监控</el-breadcrumb-item>
        <el-breadcrumb-item>测评监控列表</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="yt-header">
      <div></div>
      <div class="yt-header-toolbar">
        <el-input v-model="keyword" placeholder="通过考试名称模糊搜索" class="yt-search" @keydown.enter.native="searchExams">
          <i slot="suffix" class="el-input__icon el-icon-search" @click="searchExams"></i>
        </el-input>
      </div>
    </div>
    <div class="yt-container">
      <div class="yt-content">
        <el-table :data="examList" class="yt-table" v-loading="examLoading" element-loading-text="加载中" height="calc(100% - 35px)">
          <el-table-column label="考试名称" prop="examName" show-overflow-tooltip />
          <el-table-column label="考试时间" prop="examTime" show-overflow-tooltip />
          <el-table-column label="考试时长（分钟）" prop="limitTime" show-overflow-tooltip />
          <el-table-column label="状态">
            <template slot-scope="scope">
              <span class="dot" :style="{ backgroundColor: colorEnum[scope.row.status] }"></span>
              <span :style="{ color: colorEnum[scope.row.status] }">{{ statusEnum[scope.row.status] }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="80px">
            <template slot-scope="scope">
              <span class="tool-button" @click="toMonitorDetail(scope.row)">监考</span>
            </template>
          </el-table-column>
          <div slot="empty" id="empty-slot">
            <template v-if="!examLoading">
              <YTIcon :href="'#icon-zanwushuju'" style="font-size: 100px"></YTIcon>
            </template>
          </div>
        </el-table>
        <Page
          class="yt-page"
          :class="'yt-page-' + (total.toString().length > 1 ? total.toString().length : 2)"
          :total="total"
          :current="pageNum + 1"
          :page-size-opts="[10, 20, 40, 100]"
          :page-size="pageSize"
          @on-change="changePage"
          @on-page-size-change="changePageSize"
          show-elevator
          show-sizer
          show-total
        />
      </div>
    </div>
  </div>
</template>

<script>
import YTIcon from '@components/common/YTIcon'
import examMonitorApi from '@api/examMonitor'
export default {
  name: 'MonitorList',
  components: { YTIcon },
  data() {
    return {
      keyword: '',
      examList: [],
      colorEnum: ['', '#FFC117', '#448BFF'],
      statusEnum: ['', '即将开考', '进行中'],
      examLoading: true,
      total: 0,
      pageNum: 0,
      pageSize: 10
    }
  },
  mounted() {
    this.searchExams()
  },
  methods: {
    searchExams() {
      let payload = {
        examName: this.keyword
      }
      this.examLoading = true
      examMonitorApi
        .searchExams(payload, this.pageNum, this.pageSize)
        .then(res => {
          this.total = res.res.total
          this.examList = res.res.data
        })
        .finally(() => {
          this.examLoading = false
        })
    },
    changePage(pageNum) {
      this.pageNum = pageNum - 1
      this.searchExams()
    },
    changePageSize(pageSize) {
      this.pageSize = pageSize
      this.searchExams()
    },
    toMonitorDetail(row) {
      this.$router.push({ name: 'monitorDetail', params: { examId: row.examId } })
    }
  }
}
</script>

<style lang="less" scoped>
.dot {
  display: inline-block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  vertical-align: middle;
  margin-top: -2px;
  margin-right: 5px;
}
</style>
